import React from 'react';
import './components.css';
const Carousel = () => {

  const handleOutEvery = () =>{
    document.querySelector(".watchesname-kishi").style.display = 'none';

    document.querySelector(".watchesname-qadin").style.display = 'none';

    document.querySelector(".watchesname-ushaq").style.display = 'none';

    document.querySelector(".watchesname-aksesuar").style.display = 'none';
}

    return(
      <div className='carousel-container' onMouseEnter={() => {handleOutEvery()}}>
        <title>Saat</title>
<div id="demo" className="carousel slide " data-bs-ride="carousel" onMouseEnter={() => {handleOutEvery()}}>
<div class="carousel-indicators">
<button type="button" data-bs-target="#democar" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#democar" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#democar" data-bs-slide-to="2"></button>
  </div>
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="/img/EACarousel.jpeg"  class="d-block" />
    </div>
    <div class="carousel-item">
      <img src="/img/THCarousel.jpeg"  class="d-block" />
    </div>
    <div class="carousel-item">
      <img src="/img/AXCarousel.jpeg"  class="d-block" />
    </div>
  </div>
</div>
</div>
    )

}

export default Carousel;











import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
const Navwatch = () => {
  const [category, setCategory] = useState(null);
  const [gender, setGender] = useState(null);

  const handleEnterKishi = () => {
    document.querySelector(".watchesname-kishi").style.display = "block";
  };

  const handleEnterQadin = () => {
    document.querySelector(".watchesname-qadin").style.display = "block";
  };

  const handleEnterUshaq = () => {
    document.querySelector(".watchesname-ushaq").style.display = "block";
  };

  const handleEnterAksesuar = () => {
    document.querySelector(".watchesname-aksesuar").style.display = "block";
  };

  const handleOutEvery = () => {
    document.querySelector(".watchesname-kishi").style.display = "none";

    document.querySelector(".watchesname-qadin").style.display = "none";

    document.querySelector(".watchesname-ushaq").style.display = "none";

    document.querySelector(".watchesname-aksesuar").style.display = "none";
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);

  const handleMouseOver = (index, indexlogo) => {
    setSelectedImage(imagesArray[index]);
    setSelectedLogo(logossArray[indexlogo]);
  };

  const handleMouseOut = () => {
    setSelectedImage(null);
    setSelectedLogo(null);
  };

  const imagesArray = [
    //man
    '/img/imgCKK.jpeg',
    '/img/imgFCK.jpg',
    '/img/eoman.jpg',
    '/img/DieselK.png',
    '/img/Orient.jpg',
    '/img/AXU.jpeg',
    '/img/Ingersoll.jpg',
    '/img/SwissMAN.jpg',
    '/img/MKMan.jpg',
    '/img/Fossilman.jpg',
    '/img/THK.jpg',
    '/img/LacosteMan.jpeg',
    '/img/CasioMan.jpg',
    '/img/ArmitronK.jpeg',
    //woman
    '/img/imgCKQ.jpg',
    '/img/MKQ.jpg',
    '/img/imgFCQ.jpg',
    '/img/eowoman.jpg',
    '/img/OrientQ.jpg',
    '/img/AKQadin.jpg',
    '/img/FQ.jpg',
    '/img/THQ.jpeg',
    '/img/OBJ.jpg',
    '/img/lacosteqadin1.jpeg',
    '/img/DieselQ.png',
    '/img/DKNYQ.jpeg',
    '/img/SwissWOMAN.jpg',
    '/img/AXU.jpeg',
    '/img/CasioQ.jpg',
    '/img/ArmitronQadin.jpg',
    //ushaq
    '/img/imgCasioU.jpg',
    '/img/LacosteU.jpg',
    '/img/ArmitronU.jpg',
    //jewellery
    '/img/DJ.jpg',
    '/img/EAJ.jpg',
    '/img/FJ.jpg',
    '/img/THJ.jpg',
    '/img/OBJ.jpg',
    '/img/AXJ.jpeg',
    '/img/LJ.jpg',
    //man
    '/img/G-SHOCKMAN.jpg',
    '/img/Edifice.jpg',
    '/img/G-SHOCKWOMAN.jpg',
    '/img/OrientStar.jpg',
  ];

  const logossArray = [
    //manlogo
    '/img/CalvinKleinLOGO.jpg',
    '/img/FCLOGO.png',
    '/img/EALOGO.png',
    '/img/DieselLOGO.png',
    '/img/OrientLOGO.jpg',
    '/img/AXLOGO.png',
    '/img/IngersollLOGO.png',
    '/img/SwissLOGO.png',
    '/img/MKLOGO.png',
    '/img/FKLOGO.png',
    '/img/THLOGO.png',
    '/img/LacosteLogo.png',
    '/img/CasioLOGO.png',
    '/img/ArmitronLOGO.png',
    //womanlogo
    '/img/CalvinKleinLOGO.jpg',
    '/img/MKLOGO.png',
    '/img/FCLOGO.png',
    '/img/EALOGO.png',
    '/img/OrientLOGO.jpg',
    '/img/ANNELOGO.png',
    '/img/FKLOGO.png',
    '/img/THLOGO.png',
    '/img/OBJLOGO.png',
    '/img/LacosteLogo.png',
    '/img/DieselLOGO.png',
    '/img/DKNYLOGO.png',
    '/img/SwissLOGO.png',
    '/img/AXLOGO.png',
    '/img/CasioLOGO.png',
    '/img/ArmitronLOGO.png',
    //ushaq
    '/img/LacosteLogo.png',
    '/img/ArmitronLOGO.png',
    //jewellery
    '/img/DieselLOGO.png',
    '/img/EALOGO.png',
    '/img/FKLOGO.png',
    '/img/THLOGO.png',
    '/img/OBJLOGO.png',
    '/img/AXLOGO.png',
    '/img/LacosteLogo.png',
    //man
    '/img/EdificeLogo.png',
    '/img/G-SHOCKLOGO.jpg',
    '/img/OrientStarLogo.png',
  ];




  return (
    <>
    <title>Saat</title>
    <nav
      className="navwatch"
      onMouseEnter={() => {
        handleOutEvery();
      }}
    >
      <div
        className="ullidiv"
        onMouseEnter={() => {
          handleOutEvery();
        }}
      >
        <ul
          onMouseEnter={() => {
            handleOutEvery();
          }}
        >
       <Link to={"/cards/000"} className="allgenderlink">
          <li
            className="menwatch"
            onMouseOver={() => {
              handleEnterKishi();
            }}
            onClick={()=> handleOutEvery()}
            onMouseEnter={() => {
              handleOutEvery();
            }}
            onclick={() => setGender("000")}
          >
            Kişi Saatı
          </li>
          </Link>
          <Link to={"/cards/111"} className="allgenderlink"> 
          <li
            className="womenwatch"
            onMouseOver={() => {
              handleEnterQadin();
            }}
            onClick={()=> handleOutEvery()}
            onMouseEnter={() => {
              handleOutEvery();
            }}
            onclick={() => setGender("111")}
          >
            Qadın Saatı
          </li>
          </Link>
          <Link to={"/cards/222"} className="allgenderlink">
          <li
            className="childwatch"
            onMouseOver={() => {
              handleEnterUshaq();
            }}
            onClick={()=> handleOutEvery()}
            onMouseEnter={() => {
              handleOutEvery();
            }}
            onclick={() => setGender("222")}
          >
            Uşaq Saatı
          </li>
          </Link>
          <Link to={"/cards/333"} className="allgenderlink">
          <li
            className="jewerelly"
            onMouseOver={() => {
              handleEnterAksesuar();
            }}
            onClick={()=> handleOutEvery()}
            onMouseEnter={() => {
              handleOutEvery();
            }}
            onclick={() => setGender("333")}
          >
            Aksesuarlar
          </li>
          </Link>
        </ul>
      </div>
        
      <div
        className="watchesname-kishi"
        onMouseLeave={() => {
          handleOutEvery();
        }}
      >
        <i class="fa-solid fa-caret-up"></i>
        <div className="trendkishi">
          <>
            <h5 className="kishisaati">Kişi saatları</h5>
            <div className="trendxett"></div>
            <div className="trendh6">
         
                <br />
            <Link to={"/cards/FREDERIQUE CONSTANT/000"}  >
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    onMouseEnter={() => handleMouseOver(1,1)}
                    onMouseOut={handleMouseOut}
                    onclick={() => setCategory("FREDERIQUE CONSTANT")}
                  >
                      FREDERIQUE CONSTANT
                  </h6>
                </a>
                </Link>
              <Link to={"/cards/ARMANI/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(2,2)}
                     onMouseOut={handleMouseOut}
                    onclick={() => setCategory("ARMANI")}
                  >
                    EMPORIO ARMANI
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/DIESEL/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(3,3)}
        onMouseOut={handleMouseOut}
        onclick={() => setCategory("DIESEL")}
                  >
                    DIESEL{" "}
                  </h6>
                </a>
              </Link>
             
              <Link to={"/cards/ORIENT/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(4,4)}
        onclick={() => setCategory("ORIENT")}
        onMouseOut={handleMouseOut}
                  >
                    ORIENT
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/ORIENT STAR/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(43,41)}
        onclick={() => setCategory("ORIENT STAR")}
        onMouseOut={handleMouseOut}
                  >
                    ORIENT STAR
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/ARMANI EX/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(5,5)}
        onclick={() => setCategory("ARMANI EX")}
        onMouseOut={handleMouseOut}
                  >
                      ARMANI EXCHANGE{" "}
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/INGERSOLL/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(6,6)}
        onclick={() => setCategory("INGERSOLL")}
        onMouseOut={handleMouseOut}
                  >
                     INGERSOLL
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/SWISS MILITARY/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(7,7)}
        onclick={() => setCategory("SWISS MILITARY")}
        onMouseOut={handleMouseOut}
                  >
                     SWISS MILITARY HANOWA
                  </h6>
                </a>
              </Link>
             
              <br />
              <Link to={"/cards/MICHAEL KORS/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(8,8)}
        onclick={() => setCategory("MICHAEL KORS")}
        onMouseOut={handleMouseOut}
                  >
                      MICHAEL KORS
                  </h6>
                </a>
              </Link>
             <br />
              <Link to={"/cards/FOSSIL/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(9,9)}
        onclick={() => setCategory("FOSSIL")}
        onMouseOut={handleMouseOut}
                  >
                    FOSSIL
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/TOMMY HILFIGER/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(10,10)}
        onclick={() => setCategory("TOMMY HILFIGER")}
        onMouseOut={handleMouseOut}
                  >
                      TOMMY HILFIGER{" "}
                  </h6>
                </a>
              </Link>
              <br />
           <Link to={"/cards/CALVIN KLEIN/000"}  >
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    onMouseEnter={() => handleMouseOver(0,0)}
                    onMouseOut={handleMouseOut}
                    onclick={() => setCategory("CALVIN KLEIN")}
                  >
                      CALVIN KLEIN</h6>
                </a>
                </Link>
              <br />
              <Link to={"/cards/LACOSTE/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseEnter={() => handleMouseOver(11,11)}
        onclick={() => setCategory("LACOSTE")}
        onMouseLeave={handleMouseOut}
                  >
                    LACOSTE
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/CASIO/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(12,12)}
        onclick={() => setCategory("CASIO")}
        onMouseOut={handleMouseOut}
                  >
                    CASIO
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/CASIO EDIFICE/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(41,39)}
        onclick={() => setCategory("CASIO EDIFICE")}
        onMouseOut={handleMouseOut}
                  >
                    EDIFICE
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/CASIO G-SHOCK/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(40,40)}
        onclick={() => setCategory("CASIO G-SHOCK")}
        onMouseOut={handleMouseOut}
                  >
                    G-SHOCK
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/ARMITRON/000"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h66"
                    onMouseOver={() => handleMouseOver(13,13)}
        onclick={() => setCategory("ARMITRON")}
        onMouseOut={handleMouseOut}
                  >
                    ARMITRON
                  </h6>
                </a>
              </Link>
              <br />
            </div>
          </>
        </div>
        <div className="trendimageK">
        {selectedImage && <img src={selectedImage} alt="Selected" />}
        </div>

        <div className="logopage">
        {selectedImage && <img src={selectedLogo} alt="Selected" />}
        </div>
      </div>

      <div
        className="watchesname-qadin"
        onMouseLeave={() => {
          handleOutEvery();
        }}
      >
        <i class="fa-solid fa-caret-up"></i>
        <div className="trendkishi">
          <h5 className="kishisaati">Qadın saatları</h5>
          <div className="trendxett"></div>
          <div className="trendh6">
        
            <Link to={"/cards/MICHAEL KORS/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(15,15)}
                  onMouseOut={handleMouseOut}
                >
                    MICHAEL KORS
                </h6>
              </a>
            </Link>
            <Link to={"/cards/FREDERIQUE CONSTANT/111"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(16,16)}
        onclick={() => setCategory("FREDERIQUE CONSTANT")}
        onMouseOut={handleMouseOut}
                  >
                    FREDERIQUE CONSTANT
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/ARMANI/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(17,17)}
                     onMouseOut={handleMouseOut}
                  onclick={() => setCategory("ARMANI")}
                >
                    EMPORIO ARMANI
                </h6> 
              </a>
            </Link>
              <Link to={"/cards/ORIENT/111"}>
                <a href="" onClick={()=>{handleOutEvery()}}>
                  <h6
                    className="h16"
                    onMouseOver={() => handleMouseOver(18,18)}
        onclick={() => setCategory("ORIENT")}
        onMouseOut={handleMouseOut}
                  >
                    ORIENT
                  </h6>
                </a>
              </Link>
              <br />
           
              <Link to={"/cards/ANNE KLEIN/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(19,19)}
        onclick={() => setCategory("ANNE KLEIN")}
        onMouseOut={handleMouseOut}
                >
                    ANNE KLEIN{" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/FOSSIL/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(20,20)}
                     onMouseOut={handleMouseOut}
                  onclick={() => setCategory("FOSSIL")}
                >
                  FOSSIL{" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/TOMMY HILFIGER/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(21,21)}
        onclick={() => setCategory("TOMMY HILFIGER")}
        onMouseOut={handleMouseOut}
                >   TOMMY HILFIGER
                </h6>
              </a>
            </Link>
            <Link to={"/cards/CALVIN KLEIN/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(14,14)}
                  onMouseOut={handleMouseOut}
                >
                    CALVIN KLEIN
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Olivia Burton Watches/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
        onclick={() => setCategory("Olivia Burton Watches")}
        onMouseOver={() => handleMouseOver(22,22)}
                     onMouseOut={handleMouseOut}
                >
                    OLIVIA BURTON
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/LACOSTE/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(23,23)}
        onclick={() => setCategory("LACOSTE")}
        onMouseOut={handleMouseOut}
                >
                  LACOSTE 
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/DIESEL/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(24,24)}
        onMouseOut={handleMouseOut}
        onclick={() => setCategory("DIESEL")}
        >
                  DIESEL
                </h6>
              </a>
            </Link>
           
            <br />
            <Link to={"/cards/DKNY/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(25,25)}
        onMouseOut={handleMouseOut}
                  onclick={() => setCategory("DKNY")}
                >
                  DKNY
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/SWISS MILITARY/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(26,26)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("SWISS MILITARY")}
                >
                     SWISS MILITARY HANONWA
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/ARMANI EX/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(27,27)}
        onMouseOut={handleMouseOut}
        onclick={() => setCategory("ARMANI EX")}
        >
                    ARMANI EXCHANGE
                </h6>
              </a>
            </Link>
            <Link to={"/cards/CASIO/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(28,28)}
        onMouseOut={handleMouseOut}
        onclick={() => setCategory("CASIO")}
        >
                  CASIO
                </h6>
              </a>
            </Link>
            <Link to={"/cards/CASIO G-SHOCK/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(42,40)}
        onMouseOut={handleMouseOut}
        onclick={() => setCategory("CASIO G-SHOCK")}
        >
                  G-SHOCK
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/ARMITRON/111"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(29,29)}
        onclick={() => setCategory("ARMITRON")}
        onMouseOut={handleMouseOut}
                >
                  ARMITRON
                </h6>
              </a>
            </Link>
            <br />
          </div>
        </div>
        <div className="trendimageQ">
        {selectedImage && <img src={selectedImage} alt="Selected" />}
        </div>  

        <div className="logopageQ ">
        {selectedImage && <img src={selectedLogo} alt="Selected" />}
        </div>
      </div>

      <div
        className="watchesname-ushaq"
        onMouseLeave={() => {
          handleOutEvery();
        }}
      >
        <i class="fa-solid fa-caret-up"></i>
        <div className="trendkishiU">
          <h5 className="kishisaati">Uşaq saatları</h5>
          <div className="trendxett"></div>
          <div className="trendh6">
            <br />
            <Link to={"/cards/CASIO/222"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(30,12)}
        onclick={() => setCategory("CASIO")}
        onMouseOut={handleMouseOut}
                >CASIO</h6>
              </a>
            </Link>
           <br />
          <Link to={"/cards/LACOSTE/222"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(31,30)}
        onclick={() => setCategory("LACOSTE")}
        onMouseOut={handleMouseOut}
                >LACOSTE</h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/ARMITRON/222"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66 mt-3"
                  onMouseOver={() => handleMouseOver(32,31)}
        onclick={() => setCategory("ARMITRON")}
        onMouseOut={handleMouseOut}
                >ARMITRON
                </h6>
              </a>
            </Link>
           
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
        <div className="trendimageU">
        {selectedImage && <img src={selectedImage} alt="Selected" />}
        </div>
        <div className="logopageU">
        {selectedImage && <img src={selectedLogo} alt="Selected" />}
        </div>
      </div>

      <div
        className="watchesname-aksesuar"
        onMouseLeave={() => {
          handleOutEvery();
        }}
      >
        <i class="fa-solid fa-caret-up"></i>
        <div className="trendkishi">
          <h5 className="kishisaati">Aksesuarlar </h5>
          <div className="trendxett"></div>
          <div className="trendh6">
            <Link to={"/cards/Diesel Jewelery/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h16"
                  onMouseOver={() => handleMouseOver(33,32)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("Diesel Jewelery")}
                >
                  DIESEL
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Armani Jewelery/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}> 
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(34,33)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("ARMANI Jewelery")}
                >
                   EMPORIO ARMANI {" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Fossil Jewelery/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(35,34)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("FOSSIL Jewelery")}
                >
                  FOSSIL
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/TOMMY HILFIGER JEWELLERY/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(36,35)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("TOMMY HILFIGER JEWELLERY")}
                >
                  TOMMY HILFIGER 
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Olivia Burton Jewellery/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(37,36)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("Olivia Burton Watches Jewellery")}
                >
                  OLIVIA BURTON
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Armani EX Jewelery/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(38,37)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("ARMANI EX Jewellery")}
                >
                  ARMANI EXCHANGE
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/LACOSTE JEWELLERY/333"}>
              <a href="" onClick={()=>{handleOutEvery()}}>
                <h6
                  className="h66"
                  onMouseOver={() => handleMouseOver(39,38)}
                  onMouseOut={handleMouseOut}
                  onclick={() => setCategory("LACOSTE JEWELLERY")}
                >
                  LACOSTE
                </h6>
              </a>
            </Link>
          </div>
        </div>
        <div className="trendimageJ">
        {selectedImage && <img src={selectedImage} alt="Selected" />}
        </div>

        <div className="logopageJ">
        {selectedImage && <img src={selectedLogo} alt="Selected" />}
        </div>
      </div>
    </nav>






    <nav className="navwatch2">
    <div className="offcanvas offcanvas-start" id="demo" data-bs-dismiss="offcanvas">
  <div className="offcanvas-header">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div className="offcanvas-body">
  <Link to={"/cards/FREDERIQUE CONSTANT/000"}>
                <a href="">
                  <h6
                    className="h16"
                     onclick={() => setCategory("FREDERIQUE CONSTANT")}
                  >
                    FREDERIQUE CONSTANT
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/ARMANI/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("ARMANI")}
                     className="h16"
                  >
                    Emporio Armani
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/DIESEL/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("DIESEL")}
                     className="h66"
                  >
                    Diesel{" "}
                  </h6>
                </a>
              </Link>
             
              <Link to={"/cards/ORIENT/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("ORIENT")}
                     className="h16"
                  >
                    Orient
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/ARMANI EX/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("ARMANI EX")}
                     className="h66"
                  >
                    Armani Exchange{" "}
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/INGERSOLL/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("INGERSOLL")}
                     className="h16"
                  >
                    INGERSOLL
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/SWISS MILITARY/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("SWISS MILITARY")}
                     className="h66"
                  >
                    Swiss Military Hanowa
                  </h6>
                </a>
              </Link>
             
              <br />
              <Link to={"/cards/MICHAEL KORS/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("MICHAEL KORS")}
                     className="h66"
                  >
                    Michael Kors
                  </h6>
                </a>
              </Link>
             <br />
              <Link to={"/cards/FOSSIL/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("FOSSIL")}
                     className="h66"
                  >
                    Fossil
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/TOMMY HILFIGER/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("TOMMY HILFIGER")}
                     className="h66"
                  >
                    Tommy Hilfiger{" "}
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/LACOSTE/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("LACOSTE")}
                     className="h66"
                  >
                    Lacoste
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/CASIO/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("CASIO")}
                     className="h16"
                  >
                    Casio
                  </h6>
                </a>
              </Link>
             
              <br />
              <Link to={"/cards/ARMITRON/000"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("ARMITRON")}
                     className="h66"
                  >
                    Armitron
                  </h6>
                </a>
              </Link>
  </div>
</div>
<div className="offcanvas offcanvas-start" id="demo1" data-bs-dismiss="offcanvas">
  <div className="offcanvas-header">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div className="offcanvas-body">
  <Link to={"/cards/MICHAEL KORS/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("MICHAEL KORS")}
                     className="h16"
                >
                  Michael Kors
                </h6>
              </a>
            </Link>
            <Link to={"/cards/FREDERIQUE CONSTANT/111"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("FREDERIQUE CONSTANT")}
                     className="h16"
                  >
                    FREDERIQUE CONSTANT
                  </h6>
                </a>
              </Link>
              <Link to={"/cards/ARMANI/111"}>
              <a href="">
                <h6
                  className="h16"
                  onclick={() => setCategory("ARMANI")}
                >
                  Emporio Armani
                </h6>
              </a>
            </Link>
              <Link to={"/cards/ORIENT/111"}>
                <a href="">
                  <h6
                     onclick={() => setCategory("ORIENT")}
                     className="h16"
                  >
                    Orient
                  </h6>
                </a>
              </Link>
              <br />
              <Link to={"/cards/ANNE KLEIN/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("ANNE KLEIN")}
                     className="h66"
                >
                  Anne Klein{" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/FOSSIL/111"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("FOSSIL")}
                >
                  Fossil{" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/TOMMY HILFIGER/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("TOMMY HILFIGER")}
                     className="h66"
                >
                  Tommy Hilfiger
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Olivia Burton Watches/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("Olivia Burton Watches")}
                     className="h66"
                >
                  Olivia Burton
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/LACOSTE/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("LACOSTE")}
                     className="h66"
                >
                  Lacoste 
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/DIESEL/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("DIESEL")}
                     className="h66"
                >
                  Diesel
                </h6>
              </a>
            </Link>
           
            <br />
            <Link to={"/cards/DKNY/111"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("DKNY")}
                >
                  DKNY
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/SWISS MILITARY/111"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("SWISS MILITARY")}
                >
                  Swiss Military Hanowa
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/ARMANI EX/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("ARMANI EX")}
                     className="h66"
                >
                  Armani Exchange
                </h6>
              </a>
            </Link>
            <Link to={"/cards/CASIO/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("CASIO")}
                     className="h16"
                >
                  Casio
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/ARMITRON/111"}>
              <a href="">
                <h6
                     onclick={() => setCategory("ARMITRON")}
                     className="h66"
                >
                  Armitron
                </h6>
              </a>
            </Link>
  </div>
</div>
<div className="offcanvas offcanvas-start" id="demo2" data-bs-dismiss="offcanvas">
  <div className="offcanvas-header">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div className="offcanvas-body">
  <Link to={"/cards/LACOSTE/222"}>
              <a href="">
                <h6
                     onclick={() => setCategory("LACOSTE")}
                     className="h66"
                >
                   Lacoste
                </h6>
              </a>
            </Link>
            <Link to={"/cards/ARMITRON/222"}>
              <a href="">
                <h6
                     onclick={() => setCategory("ARMITRON")}
                     className="h66 mt-3"
                >
                  Armitron
                </h6>
              </a>
            </Link>
  </div>
</div>
<div className="offcanvas offcanvas-start" id="demo3" data-bs-dismiss="offcanvas">
  <div className="offcanvas-header">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div className="offcanvas-body">
  <Link to={"/cards/Diesel Jewelery/333"}>
              <a href="">
                <h6
                  className="h16"
                  onclick={() => setCategory("DIESEL Jewelery")}
                >
                  Diesel
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Armani Jewelery/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("Armani Jewelery")}
                >
                  Emporio Armani{" "}
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Fossil Jewelery/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("Fossil Jewelery")}
                >
                  Fossil
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/TOMMY HILFIGER JEWELLERY/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("TOMMY HILFIGER JEWELLERY")}
                >
                  Tommy Hilfiger
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Olivia Burton Jewellery/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("Olivia Burton Watches Jewellery")}
                >
                  Olivia Burton
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/Armani EX Jewelery/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("rmani EX Jewelery")}
                >
                  Armani Exchange
                </h6>
              </a>
            </Link>
            <br />
            <Link to={"/cards/LACOSTE JEWELLERY/333"}>
              <a href="">
                <h6
                  className="h66"
                  onclick={() => setCategory("LACOSTE JEWELLERY")}
                >
                  Lacoste
                </h6>
              </a>
            </Link>
  </div>
</div>
<button className="btn btn-primary" type="button"  data-bs-toggle="offcanvas" data-bs-target="#demo">
Kişi Saatı
</button>
<button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"  data-bs-target="#demo1">
Qadın Saatı
</button>
<button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"   data-bs-target="#demo2">
Uşaq Saatı
</button>
<button className="btn btn-primary" type="button" data-bs-toggle="offcanvas"   data-bs-target="#demo3">
Aksesuarlar
</button>
    </nav>
   </>
  );
};

export default Navwatch;

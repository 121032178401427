import React from "react";
import "./adminpanel.css";
const AdminPanel = () => {
  const handleLogin = () => {
    window.location.href = "https://api.saat.az/login/login";
  };
  return (
    <div className="admincontainer">
      
      <div className="adminrow">
        <div className="buyimg">
          <img src="" alt="" />
        </div>
        <div className="number">
          <br />
          <label htmlFor="Phone">Elaqe Nomrenizi Qeyd Edin:</label>
          <br />
          <input type="text" placeholder="  Elaqe Nomreniz" maxLength={13} />
          
          <br />
          <label htmlFor="Unvan">Catdirilma Unvani Qeyd Edin: </label>
          <input
            type="text"
            placeholder="  Catdirilma Unvani"
            className="inputunvan"
          />
          
          <select>
  <option className="option-val" selected value="0" >Nagd</option>
  <option className="option-val" value="1">Kart ile</option>

</select>
          
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

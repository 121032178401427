import React, { useState, useEffect }  from 'react';
import './index.css';

const Scroller = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        setIsVisible(scrollY > 100); // Появление после определенной высоты, например, 100px
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

   

    return(
        // <a href='./components/navbar' title="Go to top" className='scrollerahref'>
        // <div className='scroller' >
        //     <i class="fa-solid fa-up-long"></i>
        // </div>
        // </a>
        <div
      className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <title>Saat</title>
       <i className="fa-solid fa-up-long"></i>
    </div>
    )

}

export default Scroller;
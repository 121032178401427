import React from "react";
import { Link } from "react-router-dom";
import "./cards.css";
// , minPrice, maxPrice
const Cardscomponent = ({ p  }) => {
  // if ((minPrice && p.fld23344 < minPrice) || (maxPrice && p.fld23344 > maxPrice)) {
  //   // Если цена не входит в заданный интервал, вернуть null, чтобы компонент не был отображен
  //   return null;
  // }
  return (
    <>
      <title>Saat</title>
      <div>
        <a href={"#/buywatch/" + p.fld23343} className="ahrefgo">
          <div className="cardswatchimg">
            <img src={"/img/" + p.fld23342.replace("/", "").replace("/", "") + ".jpg"} />
          </div>
          <div className="cardsh">
            <h4>{p.fld23348}</h4>
            <h6>{p.fld23342}</h6>
            <h6>{p.fld23344}.00 AZN</h6>
            <h6 className="p62">{p.fld23362}</h6>
            <h6 className="p62">{p.fld23363}</h6>
            <h6 className="p62">{p.fld23364}</h6>
          </div>
          <Link to={"/buywatch/" + p.fld23343} className="buybutton">
            İndi AL
          </Link>
        </a>
      </div>
    </>
  );
};

export default Cardscomponent;

import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './components.css';
import Navwatch from '../navwatch';

const Navbar = () => {

    const [div1Height, setDiv1Height] = useState(0);

    useEffect(() => {
      const greenwichnav = document.querySelector('.greenwichnav');
      if (greenwichnav) {
        setDiv1Height(greenwichnav.offsetHeight);
      }
    }, []);  



    const handleEnterMediaBurger = () =>{
        document.querySelector(".dropulli").style.display = 'block'
        document.querySelector("#openi").style.display = 'none'
        document.querySelector("#closei").style.display = 'block'
    }

    const handleMoveMediaBurger = () =>{
        document.querySelector(".dropulli").style.display = 'none'
        document.querySelector("#openi").style.display = 'block'
        document.querySelector("#closei").style.display = 'none'
    }

    const handleOutEvery = () =>{
        document.querySelector(".watchesname-kishi").style.display = 'none';

        document.querySelector(".watchesname-qadin").style.display = 'none';

        document.querySelector(".watchesname-ushaq").style.display = 'none';

        document.querySelector(".watchesname-aksesuar").style.display = 'none';
    }

    const logo = "/img/Greenwich_logo.jpg";

    return(
        <>      
          <nav className="greenwichnav" onMouseEnter={() => {handleOutEvery()}} >
          <title>Saat</title>
            <div className='greenwichnavimg' onClick={()=>{handleMoveMediaBurger()}}>
                <Link to={"/#"}>
           <img src={logo} alt="Logo" onClick={()=>{handleMoveMediaBurger()}}/>
           </Link>
           </div>
           <div className='greenwichanvhac me-0'>
            <ul>
                <li className='greenwichanvh me-4'>
                    <Link to={"/"}>Ana səhifə</Link>
                </li>
                <li className='greenwichanva me-4'>
                    <Link to={"/contact"}>Əlaqə</Link>
                </li>
                <li className='greenwichanva me-4'>
                    <Link to={"/about"}>Haqqımızda</Link>
                </li>
                <li className='greenwichanva me-4'>
                    <Link to={"https://api.saat.az/Login/adminlogin"}><i class="fa-regular fa-user"></i></Link>
                </li>
            </ul>
           </div>
           <div className='dropdowncontainer'>
            <div className='dropdown' id='openi' onClick={()=>{handleEnterMediaBurger()}}><i class="fa-solid fa-bars"></i></div>
            <div className='dropdown' id='closei' onClick={()=>{handleMoveMediaBurger()}}><i class="fa-solid fa-x"></i></div>
            <div className='dropulli'>
                <ul>
                <li className='greenwichanvh me-4' onClick={()=>{handleMoveMediaBurger()}}>
                    <Link to={"/"}>Ana səhifə</Link>
                </li>
                <li className='greenwichanva me-4' onClick={()=>{handleMoveMediaBurger()}}>
                    <Link to={"/contact"}>Əlaqə</Link>
                </li>
                <li className='greenwichanva me-4' onClick={()=>{handleMoveMediaBurger()}}>
                    <Link to={"/about"}>Haqqımızda</Link>
                </li>
                <li className='greenwichanva me-4' onClick={()=>{handleMoveMediaBurger()}}>
                    <Link to={"https://api.saat.az/Login/adminlogin"}><i class="fa-regular fa-user"></i></Link>
                </li>
                </ul>

            </div>
           </div>
        </nav>  
        </>

    )
}

export default Navbar;

import React from "react";
import ReactDom from "react-dom/client";
import App from './app';
import Footer from './footer'




const root = ReactDom.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
        <Footer/>
    </React.StrictMode>
     
);


import React from "react";
import './components.css'

const Smth = () => {
    return(
        <div className="smth-container ">
            <title>Saat</title>
            <div className="modal-1 mt-5">
                <img src="/img/fossilhomepage.jpg"/>
            </div>
            <div className="modal-2 mt-5">
            <img src="/img/axhomepage.jpg"/>
            </div>
            <div className="modal-3 mt-5">
            <img src="/img/akhomepage.jpg"/>
            </div>
            <div className="modal-4 mt-5">
            <img src="/img/eahomepage.jpg"/>
            </div>
        </div>
    );
}

export default Smth;
import React from 'react';
import Description from './description'
import './components.css'


const Contact = () => {
   return(  
      <React.StrictMode>
      <Description/>
      </React.StrictMode>
   )
   
}

export default Contact;
import React from 'react';
import './descr.css';

const GreenwichAbout = () => {
    console.log('salam')

    return (
            <div className='greenwichaboutcontainer'>
                <title>Saat</title>
                    <div className='greenwichaboutdescr'>
                        <div>
                        <h5>
                        - BAYGROUP was founded in 2001.
                        </h5>
                        <h5>
                        At that time our company was in the telecommunications industry, lately we have changed our concept and got a franchise for Accessories bags and cosmetics. 
                        </h5>
                        </div>
                    </div>
                    <div className='greenwichaboutdescr2'>
                        <div>
                            <h5>
                            Since 2010 our company has been an official distributor in our region for more than 20 watch brands and accessories brands.
                            </h5>
                            <h5>
                            They are: <br/> Fossil, DKNY, Michael Kors, Marc Jacobs, Tommy Hilfiger, Anne Klein, Esprit, Armitron, Olivia Burton, Lacoste, Kenzo, Nina Ricci, Skagen, Karl Lagerfeld, Escada, Pierre Cardin, Diesel, Emporio Armani, Charriol, Aerowatch, Swiss Military Hanowa, Orient, Casio, G-Shock, Baby-G, Edifice, Sheen, Q&Q and etc. 
                            </h5>
                        </div>
                    </div>

                    <div className='greenwichaboutdescr3'>
                        <div>
                            <h2>
                                Our Places:
                            </h2>
                            <h5>
                            28 Mall 1-st floor
                            Deniz Mall - 5th floor
                            Genjlik Mall - 3rd floor
                            Metropark Mall - 3rd floor
                            Park Bulvar Mall - 2nd floor
                            Port Baku Mall - 1st floor
                            Sumqayit BRAVO SUPERMARKET
                            <br/>Zefir mall - 1st floor
                            </h5>
                        </div>
                    </div>
            </div>
    )
}

export default GreenwichAbout;
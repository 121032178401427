import React from 'react';
import './descr.css'
import GreenwichAbout from './greenwichabout';

const About = () => {
   return(  
      <React.StrictMode>
      <GreenwichAbout/>
      </React.StrictMode>
   )
   
}

export default About;
import React from "react";
import "./descr.css";

const Description = () => {
  return (
    <div className="descrcontainer">
      <title>Saat</title>
      <div className="offcanvas offcanvas-start" id="demo0">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">28MALL</h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>28 Mall, 1-ci mərtəbə</p>
         <a href="tel:+994502102368"> <p>📳 +994(50)-210-23-68</p></a>
          <iframe 
            className="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.3440591002022!2d49.844190475714576!3d40.37906655793123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307da7a06b402f%3A0xd8897cf79ec36111!2z0KLQvtGA0LPQvtCy0YvQuSDQptC10L3RgtGAIDI4IE1hbGw!5e0!3m2!1sru!2saz!4v1692619101929!5m2!1sru!2saz"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo10">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Gənclik Mall</h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Gənclik Mall, 3-cü mərtəbə</p>
          <a href="tel:+994502333501"><p>📳 +994(50)-233-35-01</p></a>
          <iframe
           className="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.393400163502!2d49.85036967571552!3d40.40013495664963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d5d5a2c1005%3A0x5ede1cc1418ccc54!2z0JPRj9C90LTQttC70LjQuiDQnNC-0LvQuw!5e0!3m2!1sru!2saz!4v1692620105544!5m2!1sru!2saz"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo20">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Metro Park</h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Metro Park, 3-cü mərtəbə</p>
         <a href="tel:+994502333502"> <p>📳 +994(50)-233-35-02</p></a>
          <iframe
           className="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.2464581801382!2d49.87028077571575!3d40.403390656451606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d4eba3f0673%3A0x651e4c4d274a74fd!2sMetropark%20Shopping%20Mall!5e0!3m2!1sru!2saz!4v1692620335907!5m2!1sru!2saz"
            // width="600"
            // height="450"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>a
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo30">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Park Bulvar </h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Park Bulvar, 2-ci mərtəbə</p>
          <a href="tel:+994502333503"><p>📳 +994(50)-233-35-03</p></a>
          <iframe
           className="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.7127031156465!2d49.847394775714065!3d40.37089425842817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dad090d9181%3A0x2b88981f2bd87785!2z0J_QsNGA0Log0JHRg9C70YzQstCw0YA!5e0!3m2!1sru!2saz!4v1692620512588!5m2!1sru!2saz"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo40">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Port Baku Mall </h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Port Baku Mall, 1-ci mərtəbə</p>
          <a href="tel:+994502333504"><p>📳 +994(50)-233-35-04</p></a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.532717038746!2d49.858455875714306!3d40.37488445818553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d71b8e4c5b9%3A0xfe50781c5645b252!2sPort%20Baku%20Mall!5e0!3m2!1sru!2saz!4v1692620674657!5m2!1sru!2saz"
            className="iframe"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo50">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Sumqayıt </h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Sumqayıt, 1-ci mərtəbə(BravoSuperstore)</p>
         <a href="tel:+994502333505"> <p>📳 +994(50)-233-35-05</p></a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24244.401794846224!2d49.65310017431644!3d40.573608699999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403091b47e7dbd9d%3A0xd572f14011d5e33e!2sBravo!5e0!3m2!1sru!2saz!4v1692620802781!5m2!1sru!2saz"
            className="iframe"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" id="demo60">
        <div className="offcanvas-header">
          <h1 className="offcanvas-title">Zerif Mall </h1>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>Zefir Mall, 1-ci mərtəbə</p>
          <a href="tel:+994502333506"><p>📳 +994(50)-233-35-06</p></a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.455881742476!2d49.79139627571448!3d40.376587758082074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6fd5f5fdf5%3A0xbda3440ccbdc6276!2sZEF%C4%B0R%20MALL!5e0!3m2!1sru!2saz!4v1692620976136!5m2!1sru!2saz"
            className="iframe"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo0"
      >
        <div className="mall-28">
          <img
            src="/img/28Mall.jpeg"
            alt=""
          />
          <div className="hoverr"><h6>28 Mall</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo10"
      >
        <div className="ganclik-mall">
          <img
            src="/img/GanclikMall.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Gənclik Mall</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo20"
      >
        <div className="metro-park">
          <img
            src="/img/MetroPark.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Metro Park</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo30"
      >
        <div className="park-bulvar">
          <img
            src="/img/ParkBulvar.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Park Bulvar</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo40"
      >
        <div className="sumqayit">
          <img
            src="/img/PortBaku.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Port Baku Mall</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo50"
      >
        <div className="zerif-mall">
          <img
            src="/img/Sumqayit.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Sumqayıt</h6></div>
        </div>
      </button>
      <button
        className="btnn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo60"
      >
        <div className="zerif-mall">
          <img
            src="/img/ZefirMall.jpg"
            alt=""
          />
          <div className="hoverr"><h6>Zefir Mall</h6></div>
        </div>
      </button>
    </div>
  );
};

export default Description;

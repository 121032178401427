import React, {useState} from 'react';
import './index.css';

const Footer = () => {
  
  const redirectToGoogleSite = () => {
    // Замените 'https://example.com' на URL сайта, на который вы хотите перейти
    window.location.href = 'https://saat.az/#/';
  };
  const redirectToInstaSite = () => {
    // Замените 'https://example.com' на URL сайта, на который вы хотите перейти
    window.location.href = 'https://www.instagram.com/saat_az/';
  };

    return(
      
        <div className="footercontainer">
          <title>Saat</title>
    <div className='bigfooter'>
            
            <div className="container p-4">
              
                <ul className='wrapper'>
                  <li className='icon facebook'>
                    <span className='tooltip'>Google</span>
                    <span><i className="fab fa-google" onClick={()=>{redirectToGoogleSite()}}></i ></span>
                  </li>
                  <li className='icon instagram'>
                    <span className='tooltip'>Instagram</span>
                    <span><i className="fab fa-instagram" onClick={()=>{redirectToInstaSite()}}></i ></span>
                  </li>
                </ul>
              {/* <a className  ="btn btn-outline-light btn-floating m-1" href="http://localhost:3000/" role="button"><i className="fab fa-google"></i ></a>
              <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/saat_az/" role="button"  ><i className="fab fa-instagram"></i ></a> */}

             
                 <section className="">
                   <div className="row">
                     <div className="col-lg-3 col-md-6 mb-4 mb-md-0" id='collg1'>
                       <h5 className="text-uppercase">FİLİALLAR:</h5>
             
                       <ul className="list-unstyled mb-0">
                         <li>
                              <h6>28 MALL</h6>
                         </li>
                         <li>
                              <h6>GƏNCLİK MALL</h6>
                        </li>
                         <li>
                         <h6>METRO PARK</h6>
                         </li>
                         <li>
                          <h6>PARK BULVAR</h6>
                         </li>
                         <li>
                          <h6>PORT BAKU MALL</h6>
                         </li>
                         <li>
                          <h6>SUMQAYIT</h6>
                         </li>
                         <li>
                          <h6>ZEFİR MALL</h6>
                         </li>
                       </ul>
                     </div>
                     <div className="col-lg-3 col-md-6 mb-4 mb-md-0" id='collg2'>
                       <h5 className="text-uppercase">FİLİALLAR:</h5>
             
                       <ul className="list-unstyled mb-0">
                         <li>
                              <h6>28 MALL -- 1-ci mərtəbə</h6>
                              <a href="tel:+994502102368"><h6>+994(50)210-23-68</h6></a>
                         </li>
                         <li>
                              <h6>GƏNCLİK MALL -- 3-cü mərtəbə</h6>
                              <a href="tel:+994502333501"><h6>+994(50)233-35-01</h6></a>
                        </li>
                         <li>
                         <h6>METRO PARK -- 3-cü mərtəbə</h6>
                         <a href="tel:+994502333502"><h6>+994(50)233-35-02</h6></a>
                         </li>
                         <li>
                          <h6>PARK BULVAR -- 2-ci mərtəbə</h6>
                          <a href="tel:+994502333503"><h6>+994(50)233-35-03</h6></a>
                         </li>
                         <li>
                          <h6>PORT BAKU MALL -- 1-ci mərtəbə</h6>
                          <a href="tel:+994502333504"><h6>+994(50)233-35-04</h6></a>
                         </li>
                         <li>
                          <h6>SUMQAYIT -- 1-ci mərtəbə</h6>
                          <a href="tel:+994502333505"><h6>+994(50)233-35-05</h6></a>
                         </li>
                         <li>
                          <h6>ZEFİR MALL -- 1-ci mərtəbə</h6>
                          <a href="tel:+994502333506"><h6>+994(50)233-35-01</h6> </a>
                         </li> 
                       </ul>
                     </div>
                     <div className='brdiv'>
                      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                     </div>
                   
                     <div className="col-lg-3 col-md-6 mb-4 mb-md-0" id='collg3'>
                       <h5 className="text-uppercase">Mərtəbə:</h5>
             
                       <ul className="list-unstyled mb-0">
                         <li>
                          <h6>1-ci mərtəbə</h6>
                         </li>
                         <li>
                          <h6>3-cü mərtəbə</h6>
                         </li>
                         <li>
                         <h6>3-cü mərtəbə</h6>
                         </li>
                         <li>
                         <h6>2-ci mərtəbə</h6>
                         </li>
                         <li>
                         <h6>1-ci mərtəbə</h6>
                         </li>
                         <li>
                         <h6>1-ci mərtəbə</h6>
                         </li>
                         <li>
                         <h6>1-ci mərtəbə</h6>
                         </li>
                       </ul>
                     </div>
                 
                   
                     <div className="col-lg-3 col-md-6 mb-4 mb-md-0" id='collg4'>
                       <h5 className="text-uppercase">FİLİALLAR İLƏ ƏLAQƏ:</h5>
             
                       <ul className="list-unstyled mb-0">
                         <li>
                          <h6>+994(50)210-23-68</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-01</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-02</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-03</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-04</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-05</h6>
                         </li>
                         <li>
                          <h6>+994(50)233-35-06</h6>
                         </li>
                       </ul>
                     </div>
                  
                     {/* <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                       <h5 className="text-uppercase">Əlavə əlaqə:</h5>
             
                       <ul className="list-unstyled mb-0">
                         <li>
                          <h6></h6>
                         </li>
                         <li>
                           <a href="#!" className="text-white">Link 2</a>
                         </li>
                         <li>
                           <a href="#!" className="text-white">Link 3</a>
                         </li>
                         <li>
                           <a href="#!" className="text-white">Link 4</a>
                         </li>
                       </ul>
                     </div> */}
                   
                   </div>
              
                 </section>
                
               </div>
            
               <div className="text-center p-3" >
                <br />
                <br />
                <br />
               </div>
     
                 </div>
           
</div>
    
 );

    }

export default Footer;
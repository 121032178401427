import React, { useEffect, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import "./buywatch.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Buywatch = () => {
  
  const { id } = useParams();
  const [fld23343, setFld23343] = useState(id);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [data, setData] = useState(null);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  useEffect(() => {
    axios
      .get(`https://api.saat.az/api/Home/Detail/` + fld23343)
      .then((res) => {
        setData(res.data);
      });
  }, [fld23343]);

  const onMouseMoveImg = (event) => {
    setCoords({
      x: event.clientX,
      y: event.clientY,
    });
    let X = event.clientX - document.querySelector(".photozoom img").offsetLeft;
    let Y = event.clientY - document.querySelector(".photozoom img").offsetTop;
    const mWidth = document.querySelector(".photozoom img").offsetWidth;
    const mHeight = document.querySelector(".photozoom img").offsetHeight;
    X = (X / mWidth) * 100;
    Y = (Y / mHeight) * 100;
    document.querySelector(".photozoom img").style.transform =
      "translate(-" + X + "%,-" + Y + "%) scale(2)";
  };

  const onMouseLeaveImg = () => {
    document.querySelector(".photozoom img").style.transform =
      "translate(-50%,-50%) scale(1)";
  };

  const onMouseEnterButton = () => {
    document.querySelector(".buynow").style.backgroundColor = "white";
    document.querySelector(".buynow").style.color = "black";
    document.querySelector(".buynow").style.borderColor = "black";
  };

  const onMouseLeaveButton = () => {
    document.querySelector(".buynow").style.backgroundColor = "black";
    document.querySelector(".buynow").style.color = "white";
  };

  const onMouseEnterPaymentt = () => {
    document.querySelector(".paymentt-top h1").style.color = "black";
    document.querySelector(".h61").style.color = "black";
    document.querySelector(".h62").style.color = "black"; 
    document.querySelector(".h63").style.color = "black";
    document.querySelector(".h64").style.color = "black";
    document.querySelector(".il2 h1").style.color = "black";
    document.querySelector(".buynow").style.backgroundColor = "black";
    document.querySelector(".buynow").style.color = "white";
  };

  const onMouseLeavePaymentt = () => {
    document.querySelector(".paymentt-top h1").style.color = "white";
    document.querySelector(".h61").style.color = "white";
    document.querySelector(".h62").style.color = "white";
    document.querySelector(".h63").style.color = "white";
    document.querySelector(".h64").style.color = "white";
    document.querySelector(".il2 h1").style.color = "white";
    document.querySelector(".buynow").style.backgroundColor = "white";
    document.querySelector(".buynow").style.color = "black";
  };

  const filterByPrice = () => {
    if (!minPrice && !maxPrice) {
      return true; // Не применять фильтр, если оба поля не заполнены
    }
    const price = parseFloat(data.fld23344);
    return (!minPrice || price >= parseFloat(minPrice)) && (!maxPrice || price <= parseFloat(maxPrice));
  };

  return (
    <>
      <title>Saat</title>
        <div className="buywatchcontainer">
          <div className="photocontainer">
            <div className="photoleft">
              <div className="frontview">
              <img src={"/img/" + (data && data.fld23342 ? data.fld23342.replace("/", "").replace("/", "") : "") + ".jpg"} alt="" />
              </div>
            </div>
            <div
              className="photozoom"
              onMouseMove={(event) => onMouseMoveImg(event)}
              onMouseLeave={(event) => onMouseLeaveImg(event)}
            >
             <img src={"/img/" + (data && data.fld23342 ? data.fld23342.replace("/", "").replace("/", "") : "") + ".jpg"} alt="" />
            </div>
          </div>
          <div
            className="paymentt"
            onMouseEnter={() => onMouseEnterPaymentt()}
            onMouseLeave={() => onMouseLeavePaymentt()}
          >
            <div className="paymentt-top">
            <h1>{data && data.fld23348}</h1>
            <h6 className="h61">{data && data.fld23344 ? data.fld23344 + ".00 AZN" : ""}</h6>
              <h6 className="h62">{data && data.fld23342}</h6>
              <h6 className="h63">Saat sayi: {data ? data.fld23345 : ""}</h6>
              <h6 className="h64">Hal-hazırda bu filiallarda mövcuddur : {data ? data.fld23353 : ""}</h6>
            </div>
            <div className="buybutton-div">
              <div className="il2">
                <h1>2 il beynəlxalq zəmanət</h1>
              </div>
              <div className="paymentsys-button">
              {data && data.fld23342 && (
  <Link to={"https://api.saat.az/login/buypage?q=" + data.fld23342}>
    <button
      className="buynow"
      onMouseEnter={() => onMouseEnterButton()} 
      onMouseLeave={() => onMouseLeaveButton()}
    >
      Indi AL
    </button>
  </Link>
)}

              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Buywatch;




import React from 'react';
import Smth from './smth'
import './components.css'
import Carousel from './carousel';

const Home = () => {

   return(  

      <React.StrictMode>
      <Carousel/>
      <Smth/>
      </React.StrictMode>
   )
   
}

export default Home;
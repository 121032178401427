import "./index.css";
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Contact from "./components/contact";
import About from "./components/about";
import Navbar from "./components/navbar";
import Navwatch from "./navwatch";
import Cards from "./cards";
import Buywatch from "./buywatch";
import Scroller from "./scroller";
import AdminPanel from "./components/adminpanel";

function App() {
  return (
    <Router>
      <Navbar />
      <Navwatch />
      <Scroller />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/adminpanel" element={<AdminPanel />} />
        <Route path="/cards/:category/:gender" element={<Cards/>} />
        <Route path="/cards/:gender" element={<Cards />} />
        <Route path="/buywatch/:id" element={<Buywatch />} />
      </Routes>
    </Router>
  );
}

export default App;

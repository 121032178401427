// import React, { useEffect, useState } from "react";
// import "./cards.css";
// import axios from "axios";
// import Cardscomponent from "./cardscomponent";
// import { useParams } from "react-router-dom";

// const Cards = () => {
//   const [InfoReg23340s, setInfoReg23340s] = useState([]);
//   const [search, setSearch] = useState("");
//   const [stranica, setStranica] = useState(0);
//   const [count, setCount] = useState(0);
//   const { category, gender } = useParams();
//   const { allgender } = useParams();
//   const [nan, setNaN] = useState(0);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let response;
//         if (category != null) {
//           response = await axios.get(
//             `https://api.saat.az/api/Home/${category}/${gender}/${nan}`
//           );
//         } else {
//           response = await axios.get(
//             `https://api.saat.az/api/Home/${gender}/${nan}`
//           );
//         }
//         setInfoReg23340s(response.data.data);
//         setCount(Math.ceil(response.data.count / 24));
//       } catch (error) {
//         console.error("Error fetching data: ", error);
//       }
//     };

//     fetchData();
//   }, [category, gender, nan]);

//   const onIconClick = () => {
//     const searchInput = document.querySelector(".searchinput");
//     if (searchInput.style.width === "0%") {
//       searchInput.style.width = "30%";
//       searchInput.style.backgroundColor = "white";
//     } else {
//       searchInput.style.width = "0%";
//       searchInput.style.backgroundColor = "transparent";
//     }
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   const handleSearch = (e) => {
//     setSearch(e.target.value);
//   };

//   const filteredCards = InfoReg23340s.filter((item) => {
//     return (
//       search.toLowerCase() === "" ||
//       item.fld23342.toLowerCase().includes(search.toLowerCase()) ||
//       item.fld23348.toLowerCase().includes(search.toLowerCase())
//     );
//   });

//   return (
//     <div>
//       <div className="searcontainer">
//         <form className="searchform">
//           <input
//             onChange={handleSearch}
//             placeholder="  Search watches: "
//             className="searchinput"
//           />
//           <i
//             className="fa-solid fa-magnifying-glass"
//             onClick={onIconClick}
//           ></i>
//         </form>
//       </div>
//       <div className="cardscontainer">
//         {filteredCards.map((item) => (
//           <div className="cardswatch" key={item.SimpleKey}>
//             <Cardscomponent p={item} />
//           </div>
//         ))}
//       </div>
//       <div className="containerpag">
//         <ul className="pagination" onClick={scrollToTop}>
//           {count > 1 && (
//             <li onClick={() => setNaN(0)}>
//               <h5>Prev</h5>
//             </li>
//           )}
//           {count > 1 &&
//             [...Array(count)].map((_, i) => (
//               <li
//                 key={i}
//                 onClick={() => {
//                   setNaN(i);
//                   setStranica(i);
//                 }}
//                 className={stranica === i ? "active" : ""}
//               >
//                 <h5>{i + 1}</h5>
//               </li>
//             ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Cards;








import React, { useEffect, useState   } from "react";
import "./cards.css";
import axios from "axios";
import Cardscomponent from "./cardscomponent";
import { useParams } from "react-router-dom";

const Cards = () => {

  const [minPrice, setMinPrice] = useState("");
  const [selectedKorpus, setSelectedKorpus] = useState("");
  const [selectedMechanism, setSelectedMechanism] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
const [maxPrice, setMaxPrice] = useState("");
  const [InfoReg23340s, setInfoReg23340s] = useState([]);
  const [search, setSearch] = useState("");
  const [stranica, setStranica] = useState(0);
  const [count, setCount] = useState(0);
  const { category, gender } = useParams();
  const { allgender } = useParams();
  const [nan, setNaN] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // Состояние для текущей страницы

  const onEnterClose = () => {
    document.querySelector(".pricefilteropen").style.display = "none";
    document.querySelector(".pricefilterclose").style.display = "block";
  };
  const onEnterOpen = () => {
    document.querySelector(".pricefilteropen").style.display = "block";
    document.querySelector(".pricefilterclose").style.display = "none";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (category != null) {
          response = await axios.get(
            `https://api.saat.az/api/Home/${category}/${gender}/${nan}`
          );
        } else {
          response = await axios.get(
            `https://api.saat.az/api/Home/${gender}/${nan}`
          );
        } 
        setInfoReg23340s(response.data.data);
        setCount(Math.ceil(response.data.count / 24));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    

    fetchData();
  }, [category, gender, nan]);


  const handleSearch = async (e) => {
    setSearch(e.target.value);
    try {
      const response = await axios.get(
        `https://api.saat.az/api/Home/Search/${e.target.value}`
      );
      setInfoReg23340s(response.data.data.slice(0, 24)); // Ограничиваем результаты поиска до 24 карточек
      setCount(Math.ceil(response.data.count / 24));
    } catch (error) {
      console.error("Error fetching search results: ", error);
    }
    setCurrentPage(0); // Сбрасываем текущую страницу при поиске
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleKorpusChange = (e) => {
    setSelectedKorpus(e.target.value);
};
const handleColorChange = (e) => {
  setSelectedColor(e.target.value);
};
const handleMechanismChange = (e) => {
  setSelectedMechanism(e.target.value);
};


const [isVisible, setIsVisible] = useState(false);

const toggleVisibility = () => {
  setIsVisible(!isVisible);
};

const handleOverlayClick = (e) => {
  if (e.target.classList.contains('overlay')) {
    toggleVisibility();
  }
};



  return (
    <div>
      <title>Saat</title>
      <div className="searcontainer">
        <form className="searchform">
          <input
            onChange={handleSearch}
            placeholder="  Search watches: "
            className="searchinput"
          />
             <i
             className="fa-solid fa-magnifying-glass"
           ></i>
        </form>
        <div className="app">
        <button onClick={toggleVisibility} className="button-30" role="button">Filtr</button>
        <div className={`overlay ${isVisible ? 'show' : ''}`} onClick={handleOverlayClick}>
          <div className={`popup ${isVisible ? 'show' : ''}`}>
            <button className="close-button" onClick={toggleVisibility}><i class="fa-solid fa-circle-xmark"></i></button>
            <div> 
              <input
    className="inputmin"
      type="tel"
      value={minPrice}
      onChange={(e) => setMinPrice(e.target.value)}
      placeholder=" Minimal Qiymət"
    />
    <input
    className="inputmax"
      type="tel"
      value={maxPrice}
      onChange={(e) => setMaxPrice(e.target.value)}
      placeholder=" Maksimal Qiymət"
    />
    </div>
    <div className="korpusdiv">
  
  <span >Korpus: </span>
  <br />
  <select className="korpusselect" onChange={handleKorpusChange}>
  <option></option>
  <option>Polad</option>
  <option>Plastik</option>
  <option>Neylon</option>
  </select>
  </div>
  <div className="colordiv">
    <span >Kəmərin Rənqi: </span>
    <br />
  <select className="colorchange" onChange={handleColorChange}>
      <option></option>
      <option>Boz</option>
      <option>Boz/Sarı</option>
      <option>Gəhvəyi</option>
      <option>Göy</option>
      <option>Qara</option>
      <option>Rose gold</option>
      <option>Sarı</option>
      <option>Yaşıl</option>
  </select>
  </div>
  <div className="mexandiv">
    <span className="mexanspan" >Mexanizm: </span>
    <br />
    <select className="mexanizmchange" onChange={handleMechanismChange}>
      <option></option>
      <option>Kvarts</option>
      <option>Mexanika</option>
  </select>
  
  </div>

          </div>
        </div>
      </div>
      </div>
     

      <div className="searcontainer-2">
        <div className="searchdiv2">
        <form className="searchform2">
          <input
            onChange={handleSearch}
            placeholder="  Search watches: "
            className="searchinput-2"
          />
          
        </form>
      </div>
        <div className="app">
        <button onClick={toggleVisibility} className="button-32" role="button">Filtr</button>
        <div className={`overlay ${isVisible ? 'show' : ''}`} onClick={handleOverlayClick}>
          <div className={`popup ${isVisible ? 'show' : ''}`}>
            <button className="close-button" onClick={toggleVisibility}><i class="fa-solid fa-circle-xmark"></i></button>
            <div> 
              <input
    className="inputmin"
      type="tel"
      value={minPrice}
      onChange={(e) => setMinPrice(e.target.value)}
      placeholder=" Minimal Qiymət"
    />
    <input
    className="inputmax"
      type="tel"
      value={maxPrice}
      onChange={(e) => setMaxPrice(e.target.value)}
      placeholder=" Maksimal Qiymət"
    />
    </div>
    <div className="korpusdiv">
  
  <span >Korpus: </span>
  <br />
  <select className="korpusselect" onChange={handleKorpusChange}>
  <option></option>
  <option>Polad</option>
  <option>Plastik</option>
  <option>Neylon</option>
  </select>
  </div>
  <div className="colordiv">
    <span >Kəmərin Rənqi: </span>
    <br />
  <select className="colorchange" onChange={handleColorChange}>
      <option></option>
      <option>Boz</option>
      <option>Boz/Sarı</option>
      <option>Gəhvəyi</option>
      <option>Göy</option>
      <option>Qara</option>
      <option>Rose gold</option>
      <option>Sarı</option>
      <option>Yaşıl</option>
  </select>
  </div>
  <div className="mexandiv">
    <span className="mexanspan" >Mexanizm: </span>
    <br />
    <select className="mexanizmchange" onChange={handleMechanismChange}>
      <option></option>
      <option>Kvarts</option>
      <option>Mexanika</option>
  </select>
  
  </div>
  </div>
          </div>
        </div>
      </div>
      <div className="cardscontainer">
      {InfoReg23340s.map((item) => (
    ((minPrice && item.fld23344 < minPrice) || (maxPrice && item.fld23344 > maxPrice) || (selectedKorpus && item.fld23362 !== selectedKorpus) || (selectedColor && item.fld23364 !== selectedColor) || (selectedMechanism && item.fld23363 !== selectedMechanism)) ? 
    null : 
    <div className="cardswatch" key={item.SimpleKey}>
        <Cardscomponent p={item} minPrice={minPrice} maxPrice={maxPrice} />
    </div>
))}



      </div>
      <div className="containerpag">
        <ul className="pagination" onClick={scrollToTop}>
          {count > 1 && (
            <li onClick={() => setNaN(currentPage)}>
              <h5>Prev</h5>
            </li>
          )}
          {count > 1 &&
            [...Array(count)].map((_, i) => (
              <li
                key={i}
                onClick={() => {
                  setCurrentPage(i);
                  setNaN(i);
                  setStranica(i);
                }}
                className={stranica === i ? "active" : ""}
              >
                <h5>{i + 1}</h5>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Cards;





